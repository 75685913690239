module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tarjetas Cuenca","short_name":"Tarjetas Cuenca","description":"Tarjetas Cuenca","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0966a8f250eac2a58e8935c499c08433"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"facebookPixel":{"pixelId":"436171176938956","cookieName":"tarjetascuenca-facebook-pixel"},"googleAnalytics":{"trackingId":"G-WYZBK77BWN","cookieName":"tarjetascuenca-google-analytics"}},
    }]
